/* main navigation
--------------------- */
#mainnav-wrapper {
    display: inline-block;
    width: $full-width;
    
    @include breakpoint(medium) {
        display: none;
    }
}

#mainnav {
    @include font-size(28);
    @include line-height(70);
    color: $white;
    margin-left: 53px;
        
    li {
        padding: 0;
        
        &:before {
            content:"";
            content: none;
        }
        
        a {
            @include alpha-attribute("color", rgba($white, 0.7), $white);
            display: block;
            text-decoration: none;
    	}  
        &:first-letter {
            text-transform: uppercase;
        }
        &:hover a {
            @include alpha-attribute("color", rgba($white, 1), $white);
        }
        &.active a {
            @include alpha-attribute("color", rgba($white, 1), $white);
            font-weight: bold;
        }
    }	
}

/* sub navigation
--------------------- */
#subnav {
	
	li {
	   
        a {
		}
		
		&:hover a {
			}
	
		&.active a {
			}
    }
}

/* Mobile navigation */
.mobilenav-btn { 
    display: none;
        
    @include breakpoint(medium) {

        float: right;
        display: block;
        color: $mobilenav-link;
        display: block;
        height: 30px;
        @include font-size($font-size-body);
        margin: 40px 40px 0 0;
        padding: 0;
        position: relative;
        text-decoration: none;
        z-index: 9999999;

        &:hover {
            color: $mobilenav-hover;    
        }
        .mobilenav-icon:before, 
        .mobilenav-icon:after,
        .mobilenav-icon span {
            background: $mobilenav-link;
            content: '';
            display: block;
            width: 26px;
            height: 4px;
            position: absolute;
            right: 0px;
            transition: none 0.5s ease 0.5s;
            transition-property: transform, top, bottom, right, opacity;
        }

        .mobilenav-icon:before {
            top: 5px;
        }
        .mobilenav-icon:after {
            bottom: 5px;
        }
        .mobilenav-icon span {
            top: 50%;
            margin-top: -2px;
        }

        .mobilenav-txt {
            display: block;
            padding: 0px 38px 4px 0px;
            text-transform: uppercase;
        }

        &.mobilenav-opened {
            
            .mobilenav-icon {
                span {
                    right: -50px;
                    opacity: 0;
                }
                &:before {
                    transform: rotate( 45deg );
                    top: 13px;
                }
                &:after {
                    transform: rotate( -45deg );
                    bottom: 13px;
                }
            }
        
        }

    } //End breakpoint 

}
@include breakpoint(medium) {
    #mainnav-mobile #mainnav {
        @include font-size($font-size-body);
        @include line-height($font-size-body);
        margin: 0;
        padding: 60px 0 0 0;
        width: $full-width;
        
        li, li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after{
            float: none;
            margin: 0;
            width: 100%;
        }
        
        a {
            color: $grey-20;
            outline: none;
        }
        &:hover,
        &.active {
            a {
                background: transparent;
            }
        }
    .mm-search {
        display: none;
        }
    }
}
        
	

