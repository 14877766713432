/* Reset elements
--------------------- */
/* Search Remove Standard Styles  */
input[type=search] { 
	-webkit-appearance: none;
}
input[type="search"]::-webkit-search-decoration, 
input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

/*  Placeholders onzichtbaar maken bij focus */
input:focus::-webkit-input-placeholder,	textarea:focus::-webkit-input-placeholder { 
    color:transparent;
}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {  /* Firefox 19+ */
    color: transparent;
}
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder { /* Firefox 18- */
    color:transparent;
}
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {
    color:transparent;
}

/* Validation Balloons in supported browsers  */
::-webkit-validation-bubble-message,
::-webkit-validation-bubble-message > div > div + *,
::-webkit-validation-bubble-icon,
::-webkit-validation-bubble-arrow,
::-webkit-validation-bubble-arrow:before {
	display: none;
	}

/* Chrome */
::-webkit-validation-bubble-message {
	display: none;
	}

::-webkit-validation-bubble-message:before {
	display: none;
	}

::-webkit-validation-bubble-arrow {
	display: none;
	}	

/* Content Form
--------------------- */
.content {  
    form {
	    display: inline-block;
	    float: none;
        margin: 0;
        padding: 0;
	    width: 100%;
    }
    legend { 
	    display: none;
	 }    
    
    fieldset { 
        border: none;
        margin: 0;
	    background: none;
        padding: 0;
    }
    
    label {
	    color: $grey-20;
	    display: inline-block;
	    @include font-size($font-size-body);
	    font-weight: normal;
	    margin: 0 0 4px 0;
	    position: relative;
        max-width: 100%;
	}    
    input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
    select, 
    textarea {
//        background-color: #f3f3f3;
        background-color: $white;
        border: none;
        border-radius: 0;
        box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15) inset, 0 0 0 transparent;
        clear: left;
        @include font-size($font-size-body);
        float: left;
        margin: 0;
        outline: 0;
        padding: 10px 10px;
        max-width: 700px;
        font-style: italic;
        width: 100%;
        
        @include breakpoint(medium) {
            background-color: #f3f3f3;
            }
        
        &:focus {
            border-color: $frm-focus;
                -Moz-box-shadow: 	0 1px 2px rgba(0, 0, 0, 0.05) inset, 0 0 2px rgba(82, 168, 236, 0.6); /* Firefox Horizontal Vertical Blur Spread Color */  
                -Webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset, 0 0 2px rgba(82, 168, 236, 0.6); /* Safari, Chrome */
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset, 0 0 2px rgba(82, 168, 236, 0.6);
            color: #333333;
            outline: 0 none;
                -webkit-transition-duration: 0.4s;  
                -moz-transition-duration: 0.4s;  
                -o-transition-duration: 0.4s;  
            transition-duration: 0.4s;  
            font-style: normal;
        }

    }
    
    .frm-field,
    .frm-checkbox,
    .frm-radio,
    .frm-select,
    .frm-textarea,
    .frm-submit {
        float: left;
        margin: 0px 0 0 0;
        position: relative;
        width: 100%;
        
        & ~ div {
            margin: 20px 0 0 0;   
        }
        
        &.frm-required > label:after { /* Required */
            content: "*"; 
            color: #666;
            display: inline;
            font-family: "FontAwesome";
            @include font-size(16);
            font-style: normal;
            font-weight: bold;
            @include line-height(16);
            padding-left: 10px;
            position: absolute;
            right: 0px;
            top: 2px;
            -webkit-font-smoothing: antialiased;
            }
        
        label {
	        float: left;
            padding-right: 10px;
            padding-top: 3px;
        }
        fieldset { 
            border: none;
	        clear: left;
	        padding: 0;
            
            label {
        	    border: none;
	            padding: 0 0 0 6px;
                width: 96%;
            }
            input {
                float: left;
                margin: 4px 0 0 0;
                overflow: hidden;
                width: 4%;
                }
        }
        &.frm-file {
            label {
                float: left;
                margin: 0;
                width: 40%;
                
                @include breakpoint(medium) {
                    width: 100%;
                }

            }
            input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="radio"]):not([type="checkbox"]) {
                float: left;
                clear: none;
                width: 60%;
                
                @include breakpoint(medium) {
                    width: 100%;
                }
            }
        }
    }

    .frm-field textarea {
        font-family: "Arial";
        @include font-size($font-size-body);
        height: 100px;
        resize: none;
    }
}


/* Selectbox
--------------------- */
.frm-select label {
	display: block;
	float: left;
	padding-right: 10px;
	}

/* Button 
-------------------- */
.content input[type="submit"], .content button {
    background-color: $green;
    @include font-size($font-size-body);
    display: inline-block;
    color: $white;
    float: right !important;
    padding: 10px 20px;
    margin: $spacing 0 $spacing 0;
    border-radius: 4px;
    border: none;
    cursor: pointer;
	float: left;
	font-weight: bold;
    margin: 0;
    
        -webkit-appearance: none;
        -moz-appearance: none;
    appearance: none;  //Remove default styling IOS
	}
	.content input[type="submit"]:hover, .content button:hover {
		background-color: #aaa;
    	border-color: #bbb;
		}

/* Bootstrapvalidator feedback
------------------------------ */
.help-block, .form-control-feedback {
    color: $frm-red;
    display: inline-block;
    float: left;
    @include line-height(16);
    max-width: 200px;
    padding: 7px 0 0 9px;
    transition: opacity 0.25s ease-in-out 0s;
    vertical-align: middle;
}
.form-control-feedback {
    float: left;
    margin-left: -32px;
    padding: 7px 0 0 9px;
    width: 22px;
    
    .frm-radio &, .frm-checkbox & {
        left: 100%;
        position: absolute;
        top: 0;
    }
}

.frm-radio .help-block, .frm-checkbox .help-block {
    padding: 9px 0 0 29px;
}

.form-control-feedback.fa-check {
    color: $frm-green;
}