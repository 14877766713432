$header-height: 20vh;
$header-height-mobile: 100px;

/* header container 
--------------------- */
header {
	height: $header-height; 
	position: relative;
    width: 100%;
    
    @include breakpoint(medium) {
        background-color: $white;
        height: $header-height-mobile;
    }
}

/* logo 
--------------------- */
#logo {
   	margin-left: 40px;
    display: inline-block;
	float: left;
	line-height: $header-height;
	height: $header-height;
    max-width: 170px;
	width: 20%;
    
    @include breakpoint(medium) {
        line-height: $header-height-mobile;
        height: $header-height-mobile;
        margin-left: 30px;
        width: 150px;
        }
    
	a {
		display: block;
		max-height: 100%;
		max-width: 100%;
		vertical-align: middle;
		
        img {
		    display: inline-block;
            vertical-align: middle;
			width: $full-width;
		}
    }
}