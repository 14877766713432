/* content container 
--------------------- */
#content-wrapper {
    min-height: 70vh;
    
    .content-left {
//        background-color: $white;
        @include flex(0 1 20%);
    }
    
    .content-right {
        
        .leveranciers & {
            margin-top: -($header-height - 5);
            
            @include breakpoint(medium) {
                margin: 0;
            }
        }
        
        @include flex(2 1 80%);
        
        /* content main */
        .content-main,
        .content-tags {
            float: right;
            clear: both;
            margin:0 40px 40px 0;
            max-width: 700px;
            padding: 20px 40px;
            width: 100%;
            
            .read-more {
                display: inline-block;
                margin: $spacing 0 $spacing 0;
                padding: 10px 0px;
            }
            
            @include breakpoint(medium) {
                float: none;
                margin: 0;
            }
            
        }	
        .content-tags {
            @include alpha-attribute("background-color", rgba($purple, 0.90), $purple);    
            
            @include breakpoint(medium) {
                @include alpha-attribute("background-color", rgba($purple, 1), $purple);
            }
            
            h2 {
                color: $white;
            }
        }
    }
    
    .block {
        @include alpha-attribute("background-color", rgba($white, 0.90), $white);
//        background-color: $white;
            
        @include breakpoint(medium) {
            @include alpha-attribute("background-color", rgba($white, 1), $white);
            }
    }
}

/* button 
--------------------- */
.btn {
    background-color: $green;
    display: inline-block;
    color: $white;
    padding: 10px 20px;
    margin: $spacing 0 $spacing 0;
    border-radius: 4px;
    
    .content-tags & {
        background-color: $white;
        margin: 0 10px $spacing 0;
        color: $green;
    }
    
    &:hover {
        background-color: darken($green, 5%);
        color: $white;
    }
}

/* leden 
--------------------- */
#members {
    display: inline-block;
    margin: 0;
    width: 100%;
    
    li {
        margin:0 10px 10px 0;
        float: left;
        line-height: 145px;
        height: 145px;
        padding: 0;
        width: 145px;
        
        &:before{
            content: "";
            content: none;
        }
        
        a {
            background-color: $white;
            border: 2px solid $grey-90;
            display: block;
            width: 100%;
            vertical-align: middle;
            height: 100%;
            text-align: center;
            padding: 0 10px;
                
            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
        &.active a {
            @include alpha-attribute("background-color", rgba($purple, 0.3), $grey-20);
            border: 2px solid $purple;             
        }
    }
}

#member-pagebrowser {
    color: $purple;
    display: inline-block;
    margin: 0;
    width: 100%;
    
    li {
        float: left;
        padding: 0;
        margin-right: 10px;
        
        &:before{
            content: "";
            content: none;
        }
        
        a {
            background-color: $grey-90;
            display: inline-block;
            text-align: center;
            width: 35px;
        }
        &.active a {
            @include alpha-attribute("background-color", rgba($purple, 0.3), $grey-20);

        }
    }
}
