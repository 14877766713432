/* layout
--------------------- */
body {
    background-color: #444;
    background-image: url("../gfx/css/bg-img.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    font-family: $font-opensans;
    @include font-size($font-size-body);
    @include line-height(30);
    min-height: 100vh;
}
	
#layout-wrapper {
	margin: 0 auto;
	max-width: $full-width;
	width: $full-width;
}

/* elements
--------------------- */
.fl-left {
	float: left;
	}

.fl-right {
	float: right;
	}