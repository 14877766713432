/* Border Radius 
------------------------------------ 
@mixin border-radius($size...) {
	border-radius: $size;
}

@mixin customBorderRadius($topLeft: 0, $topRight: 0, $bottomRight: 0, $bottomLeft: 0) {
	@if $topLeft != 0 { border-top-left-radius: $topLeft; }
	@if $topRight != 0 { border-top-right-radius: $topRight; }
	@if $bottomRight != 0 { border-bottom-right-radius: $bottomRight; }
	@if $bottomLeft != 0 { border-bottom-left-radius: $bottomLeft; }
	}
}
*/

/* Semi transparent colors
------------------------------------ */
@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

/* Font Size
------------------------------------ */
@mixin font-size($sizeValue: 12){
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.1 * $sizeValue) + rem;
}

@mixin font-size-vw($sizeValue){
  font-size: $sizeValue + vw; //fallback for old browsers
}

@mixin font-size-vh($sizeValue){
  font-size: $sizeValue + vw; //fallback for old browsers
}

/* Line Height
------------------------------------ */
@mixin line-height($sizeValue: 12){
  line-height: $sizeValue + px; //fallback for old browsers
  line-height: (0.1 * $sizeValue) + rem;
}

/* Box Shadow
------------------------------------ */ 
@mixin box-shadow($args) {
  box-shadow:$top $left $blur $color #{$inset};
}


/* Box Shadow
------------------------------------ */
@mixin filter($filter-type,$filter-amount) {
    -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
    -moz-filter: $filter-type+unquote('(#{$filter-amount})');
    -ms-filter: $filter-type+unquote('(#{$filter-amount})');
    -o-filter: $filter-type+unquote('(#{$filter-amount})');
    filter: $filter-type+unquote('(#{$filter-amount})');
    }

/* Gradient
------------------------------------ 
@mixin linear-gradient($args...) {
    background: linear-gradient($args); // Fx 10+, Op 11.6+, Ch 26+, IE 10 
}
*/

/* Flexbox
------------------------------------ */
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}