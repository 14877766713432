.flex {
    @include flexbox();
//    overflow: hidden;
    
    @include breakpoint(medium) {
        display: inline-block;
        width: 100%;
    }
    
    .col {
        @include flex(0 1 auto);
        min-width: 0; /* firefox fix use height:0; when vertical boxes */
    }
}