/* All
--------------------- */
@import "breakpoints", "colors", "mixins";

/* Foundation
--------------------- */
@import "foundation";

/* Content elements 
--------------------- */
@import "content-typography", "content-images", "content-lists", "content-tables", "content-form";

/* Screen layout
--------------------- */
@import "layout-grid", "layout-main", "layout-header", "layout-navigation", "layout-content", "layout-news", "layout-footer";

/* Fonts
--------------------- */
@import url('#{$googlefont-opensans}');
@import url('#{$iconfont-fontawesome}');

/* Source plugins
--------------------- */
@import "../js/vendor/bootstrapvalidator/v0.5.0/css/bootstrapValidator.min"; 
@import "../js/vendor/mmenu/v4.3.6/jquery.mmenu.all"; 
@import "../js/vendor/bxslider/v4.1.2/jquery.bxslider"; 

/* Plugin
--------------------- */
@import "plugin-slider";
//@import "plugin-news";
//@import "plugin-search";

/* print layout 
--------------------- */
@import "print";