/* content container
--------------------- */
.news-overview {
  width: 100%;
  float: left;
  margin-bottom: 30px;

  .news-overview-title {
    display: block;
    @include font-size(22);
    @include line-height(30);
    margin-bottom: 20px;
  }

  .news-overview-image-wrap {
    float: left;
    display: inline-block;
    width: 30%;
    
    @include breakpoint(medium) {
        float: none;
        width: 100%;
        }
  }

  .news-overview-content {
    float: left;
    display: inline-block;
    margin-left: 5%;
    width: 65%;
    
    @include breakpoint(medium) {
        float: none;
        margin-left: 0;
        width: 100%;
        }

    .news-overview-date {
      margin: 0;
      color: $grey-60;
    }

    .news-overview-text {
      margin: 0 0 10px 0;
    }

  }
}

.news-item-img{
  float: left;
  margin: 0 15px 15px 0;
}

.news-item-date{
  color: $grey-60;
  margin: 0;
}
