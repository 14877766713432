/* Typography
--------------------- */

/* Google fonts 
--------------------- */
$font-opensans : "Open Sans";
$font-opensans-args : ":400,400italic,500,500italic,700,700italic";
$googlefont-opensans : "http://fonts.googleapis.com/css?family=" + $font-opensans + $font-opensans-args;

/* Icon fonts 
--------------------- */
$iconfont-fontawesome:  "../fonts/font-awesome-4.2.0/font-awesome.min.css";	

/* Body 
--------------------- */
$font-size-body: 18; //used in layout-main and content-form 
$font-family-body: "Arial";
$spacing: 20px;

/* Paragraph
--------------------- */
p {
    color: $paragraph;
	margin: $spacing 0 $spacing 0;
}

/* Hyperlinks 
--------------------- */	
a {
	color: $link-idle;
	text-decoration: none;
	
	&:hover {
	    color: $link-hover;
    }
}
	
/* Text styles 
--------------------- */
.light {
    font-weight: 300;
    }

.bold {
	font-weight: bold;
	}
	
.bold600 {
	font-weight: 600;
	}

.italic {
	font-style: italic;
	}

/* Headers
--------------------- */
@for $index from 1 through 6 {
  h#{$index}{
    color: $purple;
    font-weight: normal;
    margin: $spacing 0 $spacing 0;
  }
}
	
h1 {
	@include font-size(36);
	@include line-height(39);
	}
	
h2 {
    @include font-size(30);
	@include line-height(32);
    
    .grid & {
        margin: $spacing 0 $spacing 0;
    }
}
	
h3 {
    @include font-size(18);
	@include line-height(29);
	}

h4 {
	@include font-size(16);
	@include line-height(26);
	}

h5 {
	@include font-size(14);
	@include line-height(23);
	}

h6 {
	@include font-size(12);
	@include line-height(19);
	}