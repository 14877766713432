/* footer container 
--------------------- */
footer {
    display: block;
    overflow: auto;
    padding: 0 40px;
	position: relative;
	width: $full-width;

    
    #footer-content {
        width: $full-width;
    
        .footer-left {
            @include flex(0 1 80%);
        }
        .footer-right {
            @include flex(0 10 20%);
        }
    }

    @include breakpoint(medium) {
        background-color: $green;
    } 
    
    small.copy {	
		@include alpha-attribute("color", rgba($white, 0.7), $white);
		display: inline-block;
        margin: $spacing 0 $spacing 0;
		@include font-size(16);
        @include line-height(12);
        
        a {
            color: $white;
        }  
	
        
        @include breakpoint(medium) {
            margin: $spacing 0 10px 0;
            @include line-height(22);
        }
    }
    .footer-links {
        margin: $spacing 0 $spacing 0;
        @include line-height(12);
        @include font-size(16);
        text-align: right;
        
        @include breakpoint(medium) {
            margin: 10px 0 $spacing 0;
            text-align: left;
        } 
        ul {
            color: $white;
            display: inline-block;
            
		    li {	
		        border-left: none;
			    float: left;
			    list-style: none;

            & ~ li {
                border-left-style: solid;
                border-left-width: 1px;
                @include alpha-attribute("border-color", rgba($white, 0.5), $white);
                margin-left: 10px;
                padding-left: 10px;
                }
                a {	
                    @include alpha-attribute("color", rgba($white, 0.7), $white);
                    
                    li.active & {
                        @include alpha-attribute("color", rgba($white, 1), $white);
                    }
                }
            }
        }
    }
}